import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/authReducer";
import settingsReducer from "./reducers/settingsReducer";
import ticketReducer from "./reducers/ticketReducer";
import teamReducer from "./reducers/teamReducer";
import compaignReducer from "./reducers/compaignReducer";
import walletHistoryReducer from "./reducers/walletHistoryReducer";
import flipSharedReducer from "./reducers/flipSharedReducer";
import notificationReducer from "./reducers/notificationReducer";
import messageReducer from "./reducers/messageReducer";
import myDepositSlice from './reducers/depositReducer';
import withdrawSlice from './reducers/withdrawReducer'
import allprofileSlice from './reducers/profileReducer';


const store = configureStore({
    reducer: {
        auth : authReducer , 
        setting : settingsReducer ,
        ticket : ticketReducer ,
        team : teamReducer ,
        compaign : compaignReducer , 
        walletHistory : walletHistoryReducer ,
        flipShare : flipSharedReducer ,
        notification : notificationReducer ,
        deposite:myDepositSlice,
        message : messageReducer,
        withdraws : withdrawSlice,
        userProfile:allprofileSlice,

    },
});

export default store;