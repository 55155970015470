import Axios from 'config/api';
import { setAllprofile,setLoading,setError } from '../reducers/profileReducer';
import { toast } from 'react-toastify';
import toastError from 'utils/toastError';
export const fetchProfile = () => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;  
        let url = `/user/profile`;
        const response = await Axios.get(url, {
            headers: { Authorization: `Bearer ${token}` }
        });    
        const { data: { data: { doc } } } = response;
         dispatch(setAllprofile(doc))
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'Something went wrong.'));
    }
};