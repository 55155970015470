import ItemNotFound from 'components/global/ItemNotFound'
import Layout from 'components/global/Layout'
import Loader from 'components/global/Loader'
import Banners from 'components/home/Banners'
import Cards from 'components/home/Cards'
import Compaign from 'components/home/Compaign'
import Filters from 'components/home/Filters'
import FlipSharedStats from 'components/home/FlipSharedStats'
import Membership from 'components/home/Membership'
import Start from 'components/home/Start'
import Wallet from 'components/home/Wallet'
import DepositPopup from '../../components/home/depositpopup'
import React, { useEffect, useState, useRef } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { HashLoader, MoonLoader } from 'react-spinners'
import { setUser } from 'redux/reducers/authReducer'
import { setCurrentPage, setDocs, setDocsCount, setPages } from 'redux/reducers/compaignReducer'
import fetcher from 'utils/fetcher'
import { useLocation,useNavigate } from 'react-router-dom';
// import { io } from 'socket.io-client';

const compaignTypes = [
    { 
        id : 1 ,
        slug : 'all' ,
        name : 'All' 
    } ,
    { 
        id : 2 ,
        slug : 'flip-campaigns' ,
        name : 'Flip Campaigns' 
    }, 
    { 
        id : 3 ,
        slug : 'flip-shared' ,
        name : 'Flip Shared' 
    } 
]

const Home = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [type , setType] = useState('all');
    const [banners , setBanners] = useState([]);
    const [categories , setCategories] = useState([]);
    const [sharedLinksCount , setSharedLinksCount] = useState(0);
    const { user } = useSelector(state => state.auth);
 
    const { currentPage , category , targetCountry , keyword , docs : compaigns } = useSelector(state => state.compaign);
    const [isDepositOpen, setIsDepositOpen] = useState(false);

    const queryKey = ['fetch-dashboard-data'];
    const { isLoading , data } = useQuery(queryKey , () => {
        return fetcher(`/compaign/dashboard` , user);
    });

    const compaignQueryKey = ['fetch-dashboard-compaigns' , currentPage , category , keyword , targetCountry ];
    const { isLoading : compaignLoading , data : compaignData } = useQuery(compaignQueryKey , () => {
        return fetcher(`/compaign?keyword=${keyword}&page=${currentPage}&targetCountry=${targetCountry}&category=${category}&status=running` , user)
    })

    useEffect(() => {
        if(data) {
            const { data : { data : { 
                banners , categories , user : userDetails , sharedLinksCount
            } } } = data;
            
            setBanners(banners);
            setCategories(categories);
            setSharedLinksCount(sharedLinksCount);

            dispatch(setUser({...userDetails , token : user?.token}))
            localStorage.setItem('user' , JSON.stringify({...userDetails , token : user?.token }))       
        }
    }, [data])
    

  

    useEffect(() => {
        if (compaignData) {
            const { data : { data : { docs , page , pages , docsCount } } } = compaignData;

            dispatch(setDocs(docs));
            dispatch(setCurrentPage(page));
            dispatch(setPages(pages));
            dispatch(setDocsCount(docsCount));
        }
    }, [compaignData])
    
  



    const toggleDepositPopup = () => {
        setIsDepositOpen(!isDepositOpen);
    };

    return (
        <Layout>

           {isDepositOpen && (
                    <DepositPopup onClose={toggleDepositPopup} /> 
                )}
            <div className=" sm:p-4 px-2 py-4 min-h-screen mb-6 h-full">
                {
                    isLoading 
                    ? 
                        <div className='w-full h-[80vh] flex items-center justify-center'>
                            <MoonLoader size={35} color='white' />
                        </div>
                    :
                        <>
                            <div>
                                <Banners 
                                banners={banners}
                                />
                            </div>
                            <div className='flex gap-4 mt-4 '>
                                <div className='md:block h-[500px] sticky top-[80px] hidden flex-[0.25]'>
                                    <div>
                                        <Start />
                                    </div>
                                    {/* <div className='mt-4'>
                                        <Membership />
                                    </div> */}
                                </div>
                                <div className='lg:flex-[0.5] md:flex-[0.75] flex-1'>
                                    <div className="md:hidden block mt-4">
                                        <Start />
                                    </div>
                                    <div className="lg:hidden block">
                                        <Wallet onDepositClick={toggleDepositPopup}/>
                                    </div>
                                    <div className='lg:mt-0 mt-4'>
                                        <Cards 
                                        sharedLinksCount={sharedLinksCount}
                                        />
                                    </div>
                                    <div className='mt-4'>
                                        <Link to='/compaign/create-new'>
                                        <button className="btn-primary py-2 px-4 w-full sm:text-base text-sm text-white">
                                            Create New Compaign
                                        </button>
                                        </Link>
                                    </div>
                                    <div className='h-[60px] mt-4 sticky top-[70px] border-b z-30'>
                                        <div className='bg-pure flex items-center justify-between rounded-md '>
                                            {
                                                compaignTypes?.map(item => (
                                                    <div 
                                                    className={`flex-1 py-4 font-semibold md:text-[15px] sm:text-sm text-xs text-center cursor-pointer ${type === item.slug ? 'border-b-4 border-b-primary text-white' : 'text-gray-400'} `} 
                                                    key={item.id}
                                                    onClick={() => setType(item.slug)}
                                                    >
                                                        {item?.name}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className='mt-4'>
                                        <Filters 
                                        type={type}
                                        setType={setType}
                                        categories={categories}
                                        />
                                    </div>
                                    <div className='mt-4'>
                                        <h3 className='heading-sm'>Latest Flip Compaigns</h3>
                                        <div className='flex flex-col gap-4 mt-4 '>
                                            {
                                                compaignLoading
                                                ? 
                                                    <Loader />
                                                :
                                                compaigns?.length > 0 
                                                ? 
                                                    compaigns?.map(item => (
                                                        <Compaign 
                                                        key={item?._id} 
                                                        compaign={item}
                                                        />
                                                    ))  
                                                : 
                                                    <ItemNotFound 
                                                    message='No Compaign Found.' 
                                                    />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='flex-[0.25] lg:block hidden h-[650px] sticky top-[80px]'>
                                    <div>

                                    <Wallet   onDepositClick={toggleDepositPopup}/>

                                    </div>
                                    <div className="mt-4">
                                        <FlipSharedStats />
                                    </div>
                                    
                                </div>
                            </div>
                        </>
                }
            </div>
        </Layout>
    )
}

export default Home