import React,{useState} from 'react';
import { PaymentElement, ElementsConsumer } from '@stripe/react-stripe-js';
import { PulseLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';

const PaymentForm = ({amount}) => {

  const navigate =useNavigate();
  const [loading, setLoading] = useState(false); 
  const handleSubmit = async (event, stripe, elements) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return; 
    }

    setLoading(true); 
    const result = await stripe.confirmPayment({
      elements,
      
      confirmParams: {
        return_url: `http://localhost:3000/success?amount=${amount}`, 
      

      },
      
    });
    setLoading(false); 
    if (result.error) {
      console.error(result.error.message); 
    } else {
      console.log('Payment successful:', result.paymentIntent); 

    }
  };
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <form onSubmit={(event) => handleSubmit(event, stripe, elements)}>
          <PaymentElement/>   
          <button 
            type="submit" 
            disabled={!stripe} 
            className="bg-primary text-white w-full py-3 my-3 rounded-lg"
          >
         {loading ?   <PulseLoader size={10} color='#fff'/> : 'Pay'}
          </button>
        </form>
      )}
    </ElementsConsumer>
  );
};

export default PaymentForm;
