import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaCheck } from "react-icons/fa";
import { deposit_create } from '../../redux/actions/depositActions';
import { useDispatch } from 'react-redux';
import Layout from 'components/global/Layout';
import {compaign_createDoc} from '../../redux/actions/compaignActions'

const Success = () => {
  const location = useLocation();
  const dispatch=useDispatch();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const amount = query.get('amount');
  const payment = query.get('payment_intent');
  useEffect(() => {
    const compingsdata = localStorage.getItem('compingsdata');

    if (amount && payment  ) {
      dispatch(deposit_create({ amount:amount, tID: payment }));
     
    }
    if (compingsdata) {
      try {
        const parsedcompingsdata = JSON.parse(compingsdata);
        console.log("Parsed Remaining Data:", parsedcompingsdata); 
        dispatch(compaign_createDoc(parsedcompingsdata,navigate));
        localStorage.removeItem('compingsdata');
      } catch (error) {
        console.error("Error parsing remainingdata:", error); 
      }
    }
    const timer = setTimeout(() => {
      navigate('/');
    }, 3000);
    return () => clearTimeout(timer);
  }, [amount, payment, dispatch, navigate]);


  return (
    <Layout>
    <div className='bg-black h-screen w-full flex flex-col space-y-8 justify-center items-center'>
      <div className='w-24 h-24 bg-green-700 rounded-full flex justify-center items-center'>
        <FaCheck className='text-4xl text-white' />
      </div>
      <p className='text-white text-4xl capitalize font-semibold'>Deposit succeeded</p>
      {amount && <p className='text-white text-xl font-semibold '>Amount: ${amount}</p>}
    </div>
    </Layout>
  );
};

export default Success;