import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Layout from 'components/global/Layout';
import { FaCheck } from "react-icons/fa";
import { deposit_create } from '../../redux/actions/depositActions';
import {compaign_createDoc} from '../../redux/actions/compaignActions';


const PaymentCallback = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [paymentDetails, setPaymentDetails] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const handleCampaignData = () => {
        const compingsdata = localStorage.getItem('compingsdata');
        if (compingsdata) {
            try {
                const parsedcompingsdata = JSON.parse(compingsdata);
                console.log("Parsed Remaining Data:", parsedcompingsdata);
                dispatch(compaign_createDoc(parsedcompingsdata, navigate));
                localStorage.removeItem('compingsdata'); 
            } catch (error) {
                console.error("Error parsing remainingdata:", error);
            }
        }
    };

    useEffect(() => {
        const verifyPayment = async () => {
            const queryParams = new URLSearchParams(window.location.search);
            const reference = queryParams.get('trxref');



            if (reference) {
                try {
                    const response = await fetch(`http://localhost:8000/api/verify-paystack-transaction/${reference}`);
                    const data = await response.json();

                    if (response.ok) {
                        setPaymentDetails({
                            status: data.data.status,
                            amount: data.data.amount,
                            reference: reference,
                        });
                    } else {
                        setError(data.data.message || 'Verification failed.');
                    }
                } catch (error) {
                    console.error('Error during verification:', error);
                    setError('An error occurred while verifying the payment.');
                } finally {
                    setLoading(false);
                }
            } else {
                setError('No transaction reference found.');
                setLoading(false);
            }
        };

        verifyPayment();
    }, []);

    useEffect(() => {
        if (paymentDetails) {   
            dispatch(deposit_create({ amount: paymentDetails.amount, tID: paymentDetails.reference }));
            handleCampaignData();
            const timer = setTimeout(() => {
                navigate('/');
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [paymentDetails, dispatch, navigate]);

    return (
        <Layout>
     
            {paymentDetails && (
                <div className='bg-black h-screen w-full flex flex-col space-y-8 justify-center items-center'>
                    <div className='w-24 h-24 bg-green-700 rounded-full flex justify-center items-center'>
                        <FaCheck className='text-4xl text-white' />
                    </div>
                    <p className='text-white text-4xl capitalize font-semibold'>Deposit succeeded</p>
                    <p className='text-white text-xl font-semibold'>Amount: ${paymentDetails.amount} </p> {/* Assuming amount is in kobo/naira */}

                </div>
            )}
        </Layout>
    );
};

export default PaymentCallback;
