import { createSlice } from '@reduxjs/toolkit';
const allprofileSlice = createSlice({
    name: 'Allprofile',
    initialState: {
        allprofile: {},
          loading : false ,
           error: null,
    },
    reducers: {
        setAllprofile(state, action) {
            state.allprofile = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload; 
        },
        setError(state, action) {
            state.error = action.payload; 
        },
    },
});

export const { setAllprofile,setLoading,setError} = allprofileSlice.actions;
export default allprofileSlice.reducer;
