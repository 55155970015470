import React, { useEffect, useState } from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from './paymetform.js';  
import { baseURL } from 'config/api.js';

const stripePromise = loadStripe('pk_live_51OQssLGaJVsp9yLvtPqdpKvtREKspOioMAyohN6HBLB9Vb8aHeI1tjzq4APPVL9zSa3OIpkhiPJPvv5jGbp5By0S001u6vfJwh'); 
const Pay = () => {
  const navigate=useNavigate();
  const location = useLocation();
  const { state } = location;   
  const amount = state?.amount;
  console.log('amount ',amount);
  const [clientSecret, setClientSecret] = useState('');
   console.log('clientsecret  :',clientSecret)   

  useEffect(() => {
    const fetchClientSecret = async () => {
      const response = await fetch(`${baseURL}/create-payment-intent`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ amount }),
      });
      const data = await response.json();
      setClientSecret(data.clientSecret);
    
    };

    if (amount) {
      fetchClientSecret();
    }
  }, [amount]);


  
  const options = {
    clientSecret,
  };

  return (
    <div className='w-full flex flex-col space-y-8 items-center pt-7 h-auto md:h-screen bg-black'>
    <div className='w-full flex flex-row justify-center' >
    {amount ? (
       <div className='flex flex-col gap-3 items-center'>
      
      <p className='text-white text-2xl capitalize'> Please proceed to deposit</p>
        </div>
        
         ) 
      : (
          <p className='text-white text-xl'>No amount selected</p>
        )}
    </div>
      
      <div className='w-11/12 sm:w-4/5 lg:w-2/4'>
        {clientSecret && (
          <Elements stripe={stripePromise} options={options} >
            <PaymentForm   amount={amount}   />
          </Elements>
        )}
      </div>

    </div>
  );
};

export default Pay;
